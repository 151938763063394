import { useContext, useEffect } from "react"
import { NextPage } from "next"
import Head from "next/head"
import Image from "next/image"
import dynamic from "next/dynamic"
import { useRouter } from "next/navigation"
import { AppContext } from "@lib/AppContext"
import { LoadingOutlined } from "@ant-design/icons"
import { Spin, Card, Col, Row, Typography } from "antd"
import { addHours, startOfToday, startOfTomorrow, startOfWeek, endOfToday } from "date-fns"
import { CommentStatus, TriggerType, useAggregateCommentQuery, useAggregateStudentGameLogQuery, useAggregateStudentQuery } from "@generated/graphql"

const { Title, } = Typography

const Loading = <LoadingOutlined style={{ fontSize: 24 }} spin />

const DashboardIndexPage: NextPage = () => {
  const { state: { user } } = useContext(AppContext)
  const router = useRouter()

  useEffect(() => {
    if (user.role === "TEACHER") {
      router.push("/samskara-categories")
    }
  }, [user, router])

  const { data: aggregateStudentData, loading: aggregateStudentLoading } = useAggregateStudentQuery({
    variables: {
      where: {
        createdAt: {
          gte: startOfWeek(new Date()).toISOString()
        }
      }
    }
  })

  const { data: aggregateCommentData, loading: aggregateCommentLoading } = useAggregateCommentQuery({
    variables: {
      where: {
        status: {
          equals: CommentStatus.PENDING
        }
      }
    }
  })

  const { data: aggregateDailyActivityData, loading: aggregateDailyActivityLoading } = useAggregateStudentGameLogQuery({
    variables: {
      where: {
        triggerType: {
          equals: TriggerType.DAILY_ACTIVITY
        },
        createdAt: {
          gte: addHours(startOfToday(), 10).toISOString(),
          lte: addHours(startOfTomorrow(), 10).toISOString()
        }
      }
    }
  })

  const { data: aggregateDailyChallengeData, loading: aggregateDailyChallengeLoading } = useAggregateStudentGameLogQuery({
    variables: {
      where: {
        triggerType: {
          equals: TriggerType.DAILY_CHALLENGE
        },
        createdAt: {
          gte: addHours(startOfToday(), 16).toISOString(),
          lte: endOfToday().toISOString()
        }
      }
    }
  })

  return (
    <>
      <Head><title>Dashboard | My Skill Shaala Admin Admin</title></Head>
      {user.role === "ADMIN" ?
        <div className="site-layout-background site-layout-main">
          <Row gutter={[16, 16]} style={{ textAlign: "center" }}>
            <Col className="gutter-row" md={6} sm={24}>
              <Card>{aggregateStudentLoading ? <Spin indicator={Loading} /> : <>
                <Title level={3}>{aggregateStudentData?.aggregateStudent._count?.id}</Title>
                <Title level={4}>New Students</Title>
              </>}
              </Card>
            </Col>
            <Col className="gutter-row" md={6} sm={24}>
              <Card>{aggregateDailyActivityLoading ? <Spin indicator={Loading} /> : <>
                <Title level={3}>{aggregateDailyActivityData?.aggregateStudentGameLog._count?.id}</Title>
                <Title level={4}>AoD Submissions</Title>
              </>}
              </Card>
            </Col>
            <Col className="gutter-row" md={6} sm={24}>
              <Card>{aggregateDailyChallengeLoading ? <Spin indicator={Loading} /> : <>
                <Title level={3}>{aggregateDailyChallengeData?.aggregateStudentGameLog._count?.id}</Title>
                <Title level={4}>CoD Submissions</Title>
              </>}
              </Card>
            </Col>
            <Col className="gutter-row" md={6} sm={24}>
              <Card>{aggregateCommentLoading ? <Spin indicator={Loading} /> : <>
                <Title level={3}>{aggregateCommentData?.aggregateComment._count?.id}</Title>
                <Title level={4}>New Submissions</Title>
              </>}
              </Card>
            </Col>
          </Row>
        </div>
        : <div style={{ marginTop: "200px", textAlign: "center", marginBottom: "200px" }}>
          <Image alt="Dashboard" src="/assets/svg/undraw_dashboard_nklg.svg" layout="fill" />
          <Title style={{ textAlign: "center" }}>Dashboard</Title>
        </div>}
    </>
  )
}

export default dynamic(() => Promise.resolve(DashboardIndexPage), {
  ssr: false
})
